const REPORT_BASE_URL = process.env.REACT_APP_BASE_API_URL;
const CONFIG_REPORT = {
    CASE_BACKEND_API_URL: `${REPORT_BASE_URL}case-interactive/case-interactive/api/`,
    STAFF_BACKEND_API_URL: `${REPORT_BASE_URL}staff/staff-service/api/`,
    KNOWLEDGE_BACKEND_URL: `${REPORT_BASE_URL}knowledge-page/knowledge-page-service/api/`,
    USER_BACKEND_API_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/user/`,
    GTM_CONTENT_REPORT_BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/gtm/charts`,
    GTM_CONTENT_CONTRIBUTION_REPORT_API_URL: `/content-availability`,
    GTM_CONTENT_USAGE_REPORT_API_URL: `/content-usage`,
    REPORT_HISTORY: `${REPORT_BASE_URL}uia/SelfServicePortal/report-history/`,
    PA_SEARCH_ANALYSIS_REPORT_BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/api`,
    BEST_BET_ANALYSIS_REPORT_BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/`,
    PROFILE_COMPLETION_REPORT_BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/profiles`,
    PROFILE_COMPLETION_DOWNLOAD_REPORT_BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/profiles/report`,
    PROFILE_COMPLETION_REPORT_SEARCH_BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/reports/profile-completion`,
    POWERBI_REPORT_TOKEN_BASE_URL: (groupId, reportId) =>
        `powerbi/group/${groupId}/report/${reportId}/embed-token`,
    BCG_SITECORE_BASE_URL: process.env.REACT_APP_SITECORE_BASE_URL,
    IT_SERVICE_DASHBOARD_PARAMS: process.env.REACT_APP_IT_SERVICE_DASHBOARD_PARAMS,
    ADOBE_GLOBAL_COMPANY_ID: process.env.REACT_APP_ADOBE_GLOBAL_COMPANY_ID,
    ADOBE_RSID: process.env.REACT_APP_ADOBE_RSID,
    KNOWLEDGE_ADOBE_RSID: process.env.REACT_APP_KNOWLEDGE_ADOBE_RSID,
    RC_ADOBE_RSID: process.env.REACT_APP_RC_ADOBE_RSID,
    LINK_ADOBE_RSID: process.env.REACT_APP_LINK_ADOBE_RSID,
    IT_DASHBOARD_CONSTANTS: {
        IT_SERVICE_DASHBOARD_HOME: '/ITService',
        STATE_KEYS: {
            SERVICE_INFO: 'serviceDetails',
            KEY_UPDATES: 'keyUpdates',
            METRICS: 'metrics',
            IMP_LINKS: 'impLinks'
        },
        // USER_GROUP: "KBI UIA Self Service Portal_Admin",
        CI_OWNERS: "KBI KSG Service Dashboard Users",
        CI_ADMINS: "KBI KSG Service Dashboard Admins"
    },
    IT_SERVICE_DASHBOARD_REPORT: {
        URL: process.env.REACT_APP_SVC_DASHBOARD_REPORT_URL
    },
    MATERIAL_INTERACTIVE_REPORT: 'Material Attachment Interactive Dashboard',
    CASE_INTERACTIVE_REPORT: 'Case Interactive Report',
    TPOF_USER_ANALYTICS: 'TPOF User Analytics',
    MICROSITE_ANALYTICS: 'Microsite Analytics',
    KP_DOWNLOADER_INFO_REPORT: "KP Downloader's Info Report",
    STAFF_INTERACTIVE_REPORT: "People Interactive Report",
    KNOWLEDGE_PAGE_DASHBOARD: "Knowledge Page Dashboard",
    ECT_REPORT: "ECT Interactive Report",
    CASE_CHARGE_BACK_TOOL: "Case Charge Back Tool Interactive Report",
    GTM_REPORT: 'GTM_Content',
    PA_SEARCH_ANALYSIS_REPORT: 'PA_Search_Analytics',
    BEST_BET_ANALYTICS: 'Best_Bet_Analytics',
    PROFILE_COMPLETION_REPORT: 'Profile_Completion_Report',
    MOST_POPULAR_AUTHOR_DOWNLOADS: 'Most Popular Authors (Downloads) (Last 12 months)',
    MATERIAL_CONTRIBUTED: 'Material Contributed in last 12 months',
    CASES_TAGGED: 'Cases tagged with self-designated expertise and skills in last 12 months',
    MATERIAL_DOWNLOADS: 'Material Downloads in last 12 months',
    RESUME_DOWNLOADS: 'Resume Downloads in last 12 months',
    PROPOSAL_BIO_DOWNLOADS: 'Proposal Bio Downloads in last 12 months',
    MOST_TAGGED_EXPERTISE: 'Most Tagged Expertise',
    MOST_TAGGED_EXPERTISE_SELF_DESIGNATED: 'Most Tagged Expertise (Self Designated)',
    MOST_TAGGED_SKILLS: 'Most Tagged Skills',
    CONTENT_COVERAGE_DASHBOARD: "Content Quality Dashboard (for SteerCo)",
    FEEDBACK_SURVEY: {
        BUTTON_LABEL: "Provide Feedback",
        SURVEY_FORM_HEADER_LABEL: "Feedback Survey",
        SURVEY_FORM_TEXTAREA_LABEL: "Provide Feedback",
        SURVEY_FORM_TEXTAREA_PLACEHOLDER: "Please provide feedback comment for this report",
        SURVEY_FORM_BUTTON_LABEL: "Submit",
        BASE_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/`,
        SURVEY_FORM_URL: "feedbacks",
        PREVIOUS_COMMENT_HEADER: "Your Previous Comments",
        PREVIOUS_COMMENT_NO_RECORD_TEXT: "No previous comments found.",
        SUCCESS_MESSAGE: "Thank you for your feedback!",
        ERROR_MESSAGE: "There was an error submitting your feedback. Please try again",
        GET_FEEDBACK_COMMENTS_URL: `${REPORT_BASE_URL}uia/SelfServicePortal/feedbacks/me`,
    },
    INTERACTIVE_REPORT_NAME: [
        'Material Attachment Interactive Dashboard',
        'Case Interactive Report',
        'Knowledge Pages Interactive Report',
        'TPOF User Analytics',
        "KP Downloader's Info Report",
        "Knowledge Dashboard Test",
        "People Interactive Report",
        "Knowledge Page Dashboard",
        "ECT Interactive Report",
        "Case Charge Back Tool Interactive Report",
        "Microsite Analytics",
        'GTM_Content',
        'PA_Search_Analytics',
        'Best_Bet_Analytics',
        'Profile_Completion_Report'
    ],
    LABEL_HEADING: {
        BASIC: 'Material Filters',
        ADVANCED: "Downloader's Filters"
    },
    KP_LABEL_HEADING: {
        BASIC: 'Knowledge Page Filters',
        ADVANCED: "Downloader's Filters"
    },

    GTM_HUB_LABEL_HEADING: {
        TRAFFIC: 'Traffic Analysis',
        CONTENT_CONTRIBUTION: "Content Contribution",
        CONTENT_USAGE: "Content Usage"
    },
    FILTERS: {
        STAFF_AFFILIATION: {
            text: "Affiliations",
            field: "affiliations",
            values: [],
            mapperField: { field: "affiliations", isArray: true }
        },
        STAFF_COMPLETION: {
            text: "Completion Percentage",
            field: "Completion",
            mapperField: { field: "Completion", isArray: true }
        },
        MICROSITE_DATE_RANGE: {
            text: 'Date Range',
            field: 'micrositeDateRange',
            mapperField: { field: '', isDate: true }
        },
        GTM_HUB_DATE_RANGE: {
            text: 'Date Range (Up to 6 months)',
            subText: '(Up to 6 months)',
            field: 'gtmHubDateRange',
            mapperField: { field: 'gtmHubDateRange', isDate: true }
        },
        ORIGINAL_PUBLISHED_DATE_RANGE: {
            text: 'Original Published Date (Up to 1 year)',
            subText: '(Up to 1 year)',
            field: 'originalPublishedDate',
            mapperField: { field: 'originalPublishedDate', isDate: true }
        },
        GTM_DOWNLOAD_DATE_RANGE: {
            text: 'Download Date (Up to 1 year)',
            subText: '(Up to 6 months)',
            field: 'downloadDate',
            mapperField: { field: 'downloadDate', isDate: true }
        },
        PA_SECTOR_NAME: {
            text: 'PA / Sector',
            field: 'paSectors',
            values: [],
            mapperField: { field: 'paSectors', isArray: true }
        },
        USER_COHORT: {
            text: 'Cohort',
            field: 'cohort',
            values: [],
            mapperField: { field: 'cohort', isArray: true }
        },
        REPORTING_PERIOD: {
            text: 'Reporting Period',
            field: 'reportingPeriod',
            values: '',
            mapperField: { field: 'reportingPeriod', isArray: false }
        },
        CONTENT_CONTRIBUTION_REPORTING_PERIOD: {
            text: 'Reporting Period',
            field: 'contentAvailabilityReportingPeriod',
            values: '',
            mapperField: { field: 'contentAvailabilityReportingPeriod', isArray: false }
        },

        CONTENT_USAGE_REPORTING_PERIOD: {
            text: 'Reporting Period',
            field: 'contentUsageReportingPeriod',
            values: '',
            mapperField: { field: 'contentUsageReportingPeriod', isArray: false }
        },
        MICROSITE_SEGMENTS: {
            text: 'Select user cohort',
            field: 'micrositeSegments',
            values: [],
            mapperField: { field: 'micrositeSegments', isDate: true }
        },
        MICROSITE_PAGE_NAME: {
            text: 'Select Microsite',
            field: 'micrositePageName',
            values: [],
            mapperField: { field: 'micrositePageName', isDate: false }
        },
        MICROSITE_TOPIC_ID: {
            text: 'Select Topic ID',
            field: 'guid',
            values: undefined,
            mapperField: { field: 'guid' }
        },
        TPOF_DATE_RANGE: {
            text: 'Date Range',
            field: 'tpofDateRange',
            mapperField: { field: '', isDate: true }
        },
        TPOF_SEGMENTS: {
            text: 'Cohort',
            field: 'tpofSegments',
            values: [],
            mapperField: { field: 'tpofSegments', isArray: true }
        },
        TPOF_PA_TOPIC_FILTER: {
            text: 'PA/Topic',
            field: 'paTopic',
            values: undefined,
            mapperField: { field: 'paTopic', isArray: false }
        },
        TPOF_TOPIC_ID: {
            text: 'Select Topic ID',
            field: 'guid',
            values: undefined,
            mapperField: { field: 'guid' }
        },
        KN_DATE: {
            text: 'Select Date',
            field: 'knDateRange',
            mapperField: { field: '', isDate: true }
        },
        KN_REPORT: {
            text: 'Select Report',
            field: 'knReport',
            values: undefined,
            mapperField: { field: 'knReport', isArray: false }
        },
        CASE_OPEN_DATE: {
            text: 'Case Open Date',
            field: 'caseOpen',
            mapperField: { field: '', isDate: true }
        },
        CASE_CLOSE_DATE: {
            text: 'Case Close Date',
            field: 'caseClose',
            mapperField: { field: '', isDate: true }
        },
        CASE_TYPE: {
            text: 'Case Type',
            field: 'caseType',
            values: [],
            mapperField: { field: 'caseType', isArray: true }
        },
        CASE_TAGGED_TO_MATERIAL: {
            text: 'Case Tagged To Material',
            field: 'caseTagged',
            mapperField: { field: 'caseTagged' },
            values: [
                { id: 'YES', text: 'Yes' },
                { id: 'NO', text: 'No' }
            ]
        },
        CASE_OFFICES: {
            text: 'Case Office',
            field: 'caseOffice',
            values: [],
            mapperField: { field: 'caseOffice', isArray: true }
        },
        INCLUDE_WITHDRAWN_CASES: {
            text: 'Include Withdrawn Cases',
            field: 'includeCaseWithdrawn',
            mapperField: { field: 'includeCaseWithdrawn' }
        },
        CASE_STATUS: {
            text: 'Case Status',
            field: 'caseStatus',
            values: [],
            mapperField: { field: 'caseStatus', isArray: true }
        },
        ATTACHMENT_ORIGINAL_PUBLISH_DATE: {
            text: 'Attachment Original Publish Date',
            field: 'attachOrgPub',
            mapperField: { field: '', isDate: true }
        },
        KNOWLEDGE_PAGE_ORIGINAL_PUBLISH_DATE: {
            text: 'KP Original Publish Date',
            field: 'knowledgePageOrgPub',
            mapperField: { field: '', isDate: true }
        },
        KNOWLEDGE_PAGE_LAST_REVISED_DATE: {
            text: 'KP Last Revised Date',
            field: 'knowledgePageLastRevDate',
            mapperField: { field: '', isDate: true }
        },
        ATTACHMENT_LAST_REVISED_DATE: {
            text: 'Attachment Last Revised Date',
            field: 'attachLastRev',
            mapperField: { field: '', isDate: true }
        },
        MATERIAL_ORIGINAL_PUBLISH_DATE: {
            text: 'Material Original Publish Date',
            field: 'matOrgPub',
            mapperField: { field: '', isDate: true }
        },
        MATERIAL_LAST_REVISED_DATE: {
            text: 'Material Last Revised Date',
            field: 'matLastRev',
            mapperField: { field: '', isDate: true }
        },
        DOWNLOADED_DATE_RANGE: {
            text: 'Download Date Range (upto 1 year)',
            field: 'download',
            mapperField: { field: '', isDate: true }
        },
        IPA: {
            text: 'IPA',
            field: 'ipa',
            values: [],
            mapperField: { field: 'ipa', isArray: true }
        },

        BEST_BET_IPA: {
            text: 'Practice Area',
            field: 'bestBetIPA',
            values: [],
            superscriptText: true,
            mapperField: { field: 'ipa', isArray: true }
        },
        FPA: {
            text: 'FPA',
            field: 'fpa',
            values: [],
            mapperField: { field: 'fpa', isArray: true }
        },
        OTHERS: {
            text: 'Others',
            field: 'others',
            values: [],
            mapperField: { field: 'others', isArray: true }
        },
        HEADER_SEARCH: {
            text: 'Text',
            field: 'search',
            values: [],
            mapperField: { field: 'headerSearchText', isArray: true }
        },
        TOPIC_TREE: {
            text: 'topicTree',
            field: 'topicTree',
            values: [],
            mapperField: { field: 'topicIds', isArray: true }
        },
        IPA_FPA_OTHERS_FLAG: {
            text: 'ipaFpaOtherFlag',
            field: 'ipaFpaOtherFlag',
            values: [
                { id: 1, text: true },
                { id: 0, text: false }
            ],
            mapperField: { field: 'ipaFpaOtherFlag' }
        },
        IPA_FPA_SUBJECT_FLAG: {
            text: 'ipaFpaSubjectFlag',
            field: 'ipaFpaSubjectFlag',
            values: [
                { id: 1, text: true },
                { id: 0, text: false }
            ],
            mapperField: { field: 'ipaFpaSubjectFlag' }
        },
        PA_SORT_DIRECTION: {
            text: 'Sort Order',
            field: 'sortDirection',
            values: [
                { id: 1, text: true },
                { id: 0, text: false }
            ],
            mapperField: { field: 'sortDirection' }
        },

        BEST_BET_SORT_DIRECTION: {
            text: 'Sort Order',
            field: 'bestBetSortColumnOrder',
            values: [
                { id: 1, text: true },
                { id: 0, text: false }
            ],
            mapperField: { field: 'bestBetSortColumnOrder' }
        },
        DOWNLOADER_PA_NAME: {
            text: "Downloader's PA Name",
            field: 'downloadersPA',
            values: [],
            mapperField: { field: 'downloadersPA', isArray: true }
        },
        KP_CMP_FLAG: {
            text: "CMP Flag",
            field: 'cmpFlag',
            mapperField: { field: 'cmpFlag' }
        },
        ATTACHMENT_CATEGORY: {
            text: 'Attachment Category',
            field: 'categoryIds',
            values: [],
            mapperField: { field: 'categoryIds', isArray: true }
        },
        ATTACHMENT_DOC_TYPE: {
            text: 'Attachment Doc Type',
            field: 'docTypeIds',
            values: [],
            mapperField: { field: 'docTypeIds', isArray: true }
        },
        MATERIAL_PAGE_STATUS: {
            text: 'Material Page Status',
            field: 'status',
            values: [],
            mapperField: { field: 'status', isArray: true }
        },
        INCLUDE_DELETED_ATTACHMENTS: {
            text: 'Include Deleted Attachments',
            field: 'inclDelAttachment',
            mapperField: { field: 'inclDelAttachment' }
        },
        CMP_FLAG: {
            text: 'CMP Flag',
            field: 'inclCMPFlag',
            mapperField: { field: 'inclCMPFlag' }
        },
        GTM_FLAG: {
            text: 'GTM Flag',
            field: 'gtmFlag',
            mapperField: { field: 'gtmFlag' }
        },
        PAGE_INDEX: {
            text: 'currentPageNumber',
            field: 'currentPageNumber',
            values: [],
            mapperField: { field: 'currentPageNumber' }
        },
        PAGE_SIZE: {
            text: 'pageSize',
            field: 'ps',
            values: [
                { id: 20, text: '50' },
                { id: 40, text: '75' },
                { id: 60, text: '100' }
            ],
            mapperField: { field: 'pageSize' }
        },
        REFRESH: {
            text: 'refresh',
            field: 'ref',
            values: [],
            mapperField: { field: '' }
        },
        DOWNLOADER_OFFICE_LEVELS: {
            text: "Downloader's Region Level",
            field: 'downloaderOfficeLevels',
            values: [],
            mapperField: { field: 'downloaderOfficeLevels', isArray: true }
        },
        DOWNLOADER_POSITION: {
            text: "Downloader's Job Profile",
            field: 'downloadJobGroup',
            values: [],
            mapperField: { field: 'downloadJobGroup', isArray: true }
        },
        DOWNLOADER_OFFICES: {
            text: "Downloader's Office",
            field: 'downloaderRegion',
            values: [],
            mapperField: { field: 'downloaderRegion', isArray: true, levelField: "downloaderOfficeLevels" }
        },
        ATTACHMENT_TYPE: {
            text: 'Attachment Type',
            field: 'docTypeIds',
            values: [],
            mapperField: { field: 'docTypeIds', isArray: true }
        },
        INCLUDE_INACTIVE_DOWNLOADER: {
            text: "Include Inactive Downloaders",
            field: "inactiveDownloaders",
            mapperField: { field: "inactiveDownloaders" }
        },
        INCLUDE_INACTIVE_STAFF: {
            text: "Include Inactive Staff",
            field: "includeInactiveStaff",
            mapperField: { field: "includeInactiveStaff" }
        },
        POSITION: {
            text: "Positions",
            field: "positions",
            values: [],
            mapperField: { field: "positions", isArray: true }
        },
        OFFICES: {
            text: "Offices",
            field: "offices",
            values: [],
            mapperField: { field: "offices", isArray: true }
        },
        KNOWLEDGE_PAGE_ORIGINAL_DATE: {
            text: 'Knowledge Page Original Date',
            field: 'orgPub',
            mapperField: { field: '', isDate: true }
        },
        KNOWLEDGE_PAGE_REVISED_DATE: {
            text: 'Knowledge Page Revised Date',
            field: 'lastRev',
            mapperField: { field: '', isDate: true }
        },
        KNOWLEDGE_PAGE_STATUS: {
            text: 'KP Status',
            field: 'knPageStatus',
            values: [],
            mapperField: { field: 'knPageStatus', isArray: true }
        },
        KNOWLEDGE_PAGE_DOWNLOADERS_INFO_KPFILTER_OFFICE: {
            text: 'KP Office',
            field: 'kpOffices',
            values: [],
            mapperField: { field: 'kpOffices', isArray: true }
        },
        BCG_INTERNAL: {
            text: 'BCG Internal',
            field: 'bcgInt',
            values: [],
            mapperField: { field: 'bcgInt', isArray: true }
        },
        SUBJECT_TAXONOMY: {
            text: 'Subject Tags',
            field: 'subjectTags',
            values: [],
            mapperField: { field: 'subjectTags', isArray: true }
        },
        CUSTODIAN_TOPIC: {
            text: 'Custodian Topic',
            field: 'custodianTopics',
            values: [],
            mapperField: { field: 'custodianTopics', isArray: true }
        },
        KNOWLEDGE_PAGE_TYPE: {
            text: 'KP Type',
            field: 'contentTypes',
            values: [

            ],
            mapperField: { field: 'contentTypes', isArray: true }
        },
        IPA_FPA_BCG_FLAG: {
            text: 'ipaFpaBcgIntFlag',
            field: 'ipaFpaBcgIntFlag',
            values: [
                { id: 1, text: true },
                { id: 0, text: false }
            ],
            mapperField: { field: 'ipaFpaBcgIntFlag' }
        },
        EMPLOYEE_STATUS: {
            text: "Employee Status",
            field: "employeeStatus",
            mapperField: { field: "employeeStatus" }
        },
        SEARCH_TEXT: {
            text: 'Text Search',
            field: 'searchText',
            values: "",
            mapperField: { field: 'searchText', isArray: false }
        },

        KP_ID: {
            text: 'KP ID',
            field: 'kpIds',
            values: "",
            mapperField: { field: 'kpIds', isArray: true }
        },

        KB_ID: {
            text: 'KB ID',
            field: 'kbIds',
            values: "",
            mapperField: { field: 'kbIds', isArray: true }
        },
        CONSUMPTION_DATE_RANGE: {
            text: 'Consumption Date Range',
            field: 'consumption',
            mapperField: { field: 'consumption', isDate: true }
        },

        BEST_BET_SEARCH_DATE_RANGE: {
            text: 'Search Date Range',
            field: 'searchDateRange',
            mapperField: { field: 'searchDateRange', isDate: true }
        },

        BEST_BET_SEARCH_SORT: {
            text: 'Sort By',
            field: 'bestBetSortColumn',
            values: [],
            mapperField: { field: 'bestBetSortColumn' }
        },
        PA_SEARCH_SORT: {
            text: 'Sort By',
            field: 'sortColumn',
            values: [
                { id: 'DOWNLOAD_CLICKS', text: 'Download Clicks' },
                { id: 'EMAIL_CLICKS', text: 'Email Clicks' },
                { id: 'PIN_CLICKS', text: 'Pin Clicks' },
                { id: 'COPY_DETAILS', text: 'Copy Details' },
                { id: 'PREVIEW_CLICKS', text: 'Preview Clicks' },
                { id: 'VIEW_MATERIAL', text: 'View Material' },
                { id: 'TOTAL_CONSUMPTION', text: 'Total Consumption' },
            ],
            mapperField: { field: 'sortColumn', }
        },
        BCG_SUBSIDIARY: {
            text: 'BCG Subsidiary',
            field: 'bcgSubsidiary',
            values: [],
            mapperField: { field: 'bcgSubsidiary', isArray: true }
        },
        PROFILE_COMPLETION_COHORT: {
            text: 'Cohort',
            field: 'cohort',
            values: [],
            mapperField: { field: 'cohort', isArray: true }
        },
        CAREER_TRACK: {
            text: 'Career Track',
            field: 'careerTrack',
            values: [],
            mapperField: { field: 'careerTrack', isArray: true },
            tooltipText: 'BST users are currently excluded from default selection as Profile Completion is inactive for BST users.'
        },
        WORKER_TYPE: {
            text: 'Worker Type',
            field: 'workerType',
            values: [],
            mapperField: { field: 'workerType', isArray: true }
        },
        WORKER_STATUS: {
            text: 'Worker Status',
            field: 'workerStatus',
            values: [],
            mapperField: { field: 'workerStatus', isArray: true }
        },
        PROFILE_COMPLETION_REGION: {
            text: 'Region',
            field: 'region',
            values: [],
            mapperField: { field: 'region', isArray: true }
        },
        PROFILE_DASHBOARD_PERCENTAGE: {
            text: 'Completion Percentage',
            field: 'minMaxCompletionPercentage',
            values: [
                { id: '0-40', text: '0-40% - Missing critical data' },
                { id: '41-80', text: '41-80% - Limited data available' },
                { id: '81-99', text: '81-99% - Profile complete but out of date' },
                { id: '100', text: '100% - Profile complete (updated within last 12 months)' },
            ],
            mapperField: { field: 'minMaxCompletionPercentage', isArray: true }
        },
        SKILLS: {
            text: "Skills",
            field: "skill",
            mapperField: { field: "skill", isArray: true }
        },
        SECTIONS: {
            text: "Sections",
            field: "section",
            values: [],
            mapperField: { field: "section", isArray: true }
        },
        UPDATED_LAST_12MONTHS: {
            text: "Updated in last 12 months",
            field: "updatedLast12Months",
            mapperField: { field: "updatedLast12Months" }
        },
        TOOLS_AND_SOLUTIONS: {
            text: "Tools & Solutions",
            field: "toolsAndSolution",
            mapperField: { field: "toolsAndSolution", isArray: true }
        },
        PROFILE_COMPLETION_SUBJECTS: {
            text: 'Subjects',
            field: 'subject',
            values: [],
            mapperField: { field: 'subject', isArray: true }
        },
        PAAF: {
            text: "PAAF",
            field: "paaf",
            mapperField: { field: "paaf", isArray: true }
        },
        TP_ROLES: {
            text: 'PA Leadership Roles',
            field: 'tpRole',
            values: [],
            mapperField: { field: 'tpRole', isArray: true }
        },
        PROFILE_COMPLETION_PRACTICE_AREA: {
            text: 'Practice Area',
            field: 'practiceArea',
            values: [],
            mapperField: { field: 'practiceArea', isArray: true },
            tooltipText: 'As per PA Affiliation',
        },
    },
    FILTER_MAP: 'availableFilterFieldMap',
    API_URLS: {
        INTERACTIVE_REPORT_DATA: '/attachments/report/',
        INTERACTIVE_REPORT_DOWNLOAD: '/attachments/report/exportCsvAsync',
        GET_GTM_HUB_REPORT_CONTENT_USAGE_DOWNLOAD: '/exportCsvAsync',
        GET_ATTACHMENT_CATEGORY: '/attachments/report/filter/categoryType',
        GET_ATTACHMENT_DOC_TYPE: '/attachments/report/filter/docType',
        GET_MATERIAL_PAGE_STATUS: '/attachments/report/filter/materialStatus',
        GET_REPORT_COLUMN: '/attachments/report/filter/headers',
        GET_REPORT_DOWNLOAD_HISTORY: '/attachments/report/exportHistory/',
        GET_OTHERS_FILTERS: '/attachments/report/filter/otherTopicTree',
        GET_ATTACHMENTS_DATA: '/attachments/dashboard/docTypes',
        GET_AVAILABILITY_DATA: '/attachments/dashboard/availability',
        GET_KNOWLEDGE_PAGE_DATA: '/attachments/dashboard/kpInfo',
        GET_DOWNLOADS_DATA: '/attachments/dashboard/downloads',
        GET_INTERACTIVE_REPORT_DATA: 'report/all',
        GET_CASE_OFFICE: 'report/filter/regionCodeTreeV2',
        GET_KP_DOWNLOAD_OFFICE: '/kp/downloaders/report/filters/regionCodeTree',
        GET_STAFF_OFFICE: 'report/filter/regionCodeTree',
        GET_CAREER_TRACK: 'report/filter/careerTrack',
        GET_WORKER_TYPE: 'report/filter/workerType',
        GET_WORKER_STATUS: 'report/filter/workerStatus',
        GET_CASE_TYPE: 'report/filter/caseType',
        GET_CASE_STATUS: 'report/filter/caseStatus',
        GET_ADOBE_TOKEN: '/adobe/get-token',
        GET_ADOBE_DATA: '/adobe/get-data',
        GET_INTERACTIVE_REPORT_DOWNLOAD: "report/exportCsvAsync",
        GET_INTERACTIVE_REPORT_DOWNLOAD_HISTORY: "report/exportHistory/",
        GET_GTM_CONTENT_USAGE_REPORT_DOWNLOAD_HISTORY: "report/exportHistory/",

        GET_BCG_USER_STATS: 'staffing/monthlyCount',
        GET_PROFILE_COMPLETION: 'staff/details/profile-completion',
        GET_DOWNLOAD_POSITION: '/attachments/report/filter/staffPosition',
        GET_KP_DOWNLOAD_POSITION: '/kp/downloaders/report/filters/downPosition',
        DOWNLOAD_REPORT_DATA: '/attachments/downloaders/report',
        KP_DOWNLOADER_INFO_REPORT_DATA: '/kp/downloaders/report/fetch',
        MICROSITE_ANALYTICS_REPORT_DATA: '/kp/downloaders/report/fetch',
        DOWNLOADER_REPORT_DOWNLOAD: '/attachments/downloaders/exportCsvAsync',
        KP_DOWNLOADER_REPORT_DOWNLOAD: '/kp/downloaders/report/exportCsvAsync',
        MICROSITE_ANALYTICS_REPORT_DOWNLOAD: '/microsite-analytics/report/exportCsvAsync',
        PA_SEARCH_ANALYTICS_REPORT_DOWNLOAD: '/pa-analytics/exportCsvAsync',
        BEST_BET_ANALYTICS_REPORT_DOWNLOAD: '/best-bet/exportCsvAsync',
        PROFILES_COMPLETION_REPORT_DOWNLOAD: '/exportCsvAsync',
        GET_DOWNLOAD_DETAILS: '/attachments/downloaders/details',
        GET_KP_DOWNLOAD_DETAILS: '/kp/downloaders/report/details',
        GET_KN_CONTENT_URL: '/attachments/counts/knowledge-health-dashboard',
        GET_IPA_TREE: '/report/filter/industryTopicTree',
        GET_FPA_TREE: '/report/filter/functionalTopicTree',
        GET_BCG_INTERNAL_TREE: '/report/filter/bcgInternalTopicTree',
        GET_CONTENT_TYPE: "/report/filter/knPageContentType",
        GET_PAGE_STATUS: "/report/filter/knPageStatus",
        GET_KNOWLEDGE_OFFICE: '/report/filter/knPageOfficeV2',
        GET_ECT_INTERACTIVE_REPORT: "report/ect/all",
        DOWNLOAD_ECT_REPORT: "report/ect/exportCsvAsync",
        GET_DASHBOARD_CONTENT_TYPE: "dashboard/contentTypes",
        GET_DASHBOARD_AVAILABILITY: "dashboard/availability",
        GET_CASE_CHARGE_REPORT: "report/exportCsv",
        GET_PROFILE_POSITION: "report/filter/staffPosition",
        GET_KNOWLEDGE_PAGE_STATUS: "kp/downloaders/report/filters/kpPageStatus",
        GET_KNOWLEDGE_PAGE_TYPES: "kp/downloaders/report/filters/kpPageType",
        GET_CUSTODIAN_TOPICS: "kp/downloaders/report/filters/custodianTopic",
        GET_KP_DOWNLOAD_PA: "/kp/downloaders/report/filters/downPA",
        GET_KP_DOWNLOAD_HISTORY: '/kp/downloaders/report/exportHistory/',
        GET_KP_DOWNLOADERS_INFO_KPFILTER_OFFICE: '/kp/downloaders/report/filters/kpRegionCodeTree',
        GET_PA_SEARCH_ANALYSIS_REPORT: '/pa-analytics/fetch',
        GET_BEST_BET_ANALYSIS_REPORT: '/best-bet-analytics/fetch',
        GET_PA_SEARCH_ITEMS: '/pa-analytics/v1/searchTerms',
        GET_BEST_BET_SORT_OPTIONS: '/best-bet-analytics/filters/search-criteria',
        GET_BEST_BET_HIERARCHY_FILTER: '/best-bet-analytics/filters/hierarchy',
        GET_BEST_BET_SUMMARY_DATA: 'reports/best-bet/summary',
        GET_BEST_BET_DETAIL_DATA: 'reports/best-bet/fetch',
        GET_BEST_BET_KP_TERMS: 'reports/best-bet/',
        GET_KP_DOWNLOAD_JOB_GROUP: "/kp/downloaders/report/filters/downJobGroup",

        GET_PROFILE_COMPLETION_PROPOSAL_BIO: '/proposal-bio',
        GET_PROFILE_COMPLETION_BIO_DOWNLOAD: '/bio-downloads',
        GET_PROFILE_COMPLETION_RESUME_DOWNLOAD: '/resume-downloads',

        GET_PROFILE_COMPLETION_COMPLETION_TREND: '/trend',
        GET_PROFILE_COMPLETION_SECTION_COMPLETION: '/section',

        GET_PROFILE_COMPLETION_MATERIAL_DOWNLOAD: '/material-downloads',
        GET_PROFILE_COMPLETION_MATERIAL_CONTRIBUTED: '/material-contribution',
        GET_PROFILE_COMPLETION_EXPERTISE_AND_SKILLS: '/cases-with-expertise-skill',
        GET_PROFILE_COMPLETION_CASES: '/cases-with-expertise-skills',
        GET_PROFILE_COMPLETION_POPULAR_AUTHOR: '/popular-author',

        // PROFILE_COMPLETION Filters
        GET_PROFILE_COMPLETION_BCG_SUBSIDIARY: '/filter/BcgSubsidiary',
        GET_PROFILE_COMPLETION_COHORT: '/filter/Cohort',
        GET_PROFILE_COMPLETION_CAREER_TRACK: '/filter/CareerTrack',
        GET_PROFILE_COMPLETION_REGION: '/filter/Region',
        GET_PROFILE_COMPLETION_AFFILIATION: '/filter/Affiliation',
        GET_PROFILE_COMPLETION_GENDER: '/filter/Gender',
        GET_PROFILE_COMPLETION_ROLE: '/filter/Role',
        GET_PROFILE_COMPLETION_SKILLS: '/filter/Skills',
        GET_PROFILE_COMPLETION_SECTIONS: '/filter/Sections',
        GET_PROFILE_COMPLETION_TOOLS_AND_SOLUTIONS: '/filter/ToolsAndSolutions',
        GET_PROFILE_COMPLETION_EXPERTISE: '/filter/Expertise',
        GET_PROFILE_COMPLETION_IPA: '/filter/IndustryPracticeArea',
        GET_PROFILE_COMPLETION_FPA: '/filter/FunctionalPracticeArea',
        GET_PROFILE_COMPLETION_SUBJECTS: '/filter/Subjects',
        GET_PAAF: '/filter/Affiliation',
        GET_TP_ROLES: '/filter/Role',
        GET_PROFILE_COMPLETION_PRACTICE_AREA_FILTER: 'filter/PracticeArea',
        GET_PROFILE_COMPLETION_WORKER_TYPE: '/filter/WorkerType',
        GET_PROFILE_COMPLETION_WORKER_STATUS: '/filter/WorkerStatus',


        // PROFILE_COMPLETION Search
        GET_PROFILE_COMPLETION_SUMMARY: '/summary',
        // change accordingly
        GET_PROFILE_COMPLETION_PRACTICE_AREA: '/pa-bubble-chart',
        GET_PROFILE_COMPLETION_EXPERTISE_SEARCH_DATA: '/pa-expertise-chart',
        GET_PROFILE_COMPLETION_SKILLS_SEARCH_DATA: '/pa-skills-chart',
        GET_PROFILE_COMPLETION_SECTIONS_SEARCH_DATA: '/sections',
        GET_PROFILE_COMPLETION_REGION_SEARCH_DATA: '/office-data'
    },
    IT_SERVICE_URLS: {
        GET_ALL_SERVICES: '/knowledge-it-dashboard/services',
        ADD_UPD_DEL_SERVICE: '/knowledge-it-dashboard/services',
        CRUD_TIME_LABELS: '/knowledge-it-dashboard/time-interval',
        CRUD_KEY_UPDATES: (serviceId, timeIntervalId) =>
            `/knowledge-it-dashboard/key-update/${serviceId}/${timeIntervalId}`,
        CRUD_KEY_METRICS: (serviceId, timeIntervalId) =>
            `/knowledge-it-dashboard/metrics/${serviceId}/${timeIntervalId}`,
        CRUD_IMP_LINKS: serviceId => `/knowledge-it-dashboard/link/${serviceId}`,
        CRUD_COMMENTS: (serviceId, timeIntervalId) =>
            `/knowledge-it-dashboard/comments/${serviceId}/${timeIntervalId}`,
        CRUD_ACTION_ITEMS: (serviceId, timeIntervalId) =>
            `/knowledge-it-dashboard/action-items/${serviceId}/${timeIntervalId}`
    },
    DISPLAY_TEXT: {
        NO_DATA_IN_DROPDOWN: 'No more options to display'
    },
    DOWNLOAD_REPORT_MESSAGE:
        "Your download request has been received, you will receive your report via email shortly.",
    DEFAULT_AFFILIATION: "TEVBRA",
    DEFAULT_MATERIAL_STATUS: "Published",
    DEFAULT_KNOWLEDGE_PAGE_STATUS: "Published",
    DEFAULT_PA_SEARCH_SORT_COLUMN: "TOTAL_CONSUMPTION",
    DEFAULT_BEST_BET_SEARCH_SORT_COLUMN: "Searches",

    APP_NOTIFICATION_TYPE: {
        ERROR: "ERROR",
        SUCCESS: "SUCCESS",
        WARN: "WARN",
        DOWNLOAD: "DOWNLOAD"
    },
};
export default CONFIG_REPORT;
